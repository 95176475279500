import { CREATE_AGENCY, DELETE_AGENCY, DELETE_IMAGE_AGENCY, UPDATE_AGENCY, UPLOAD_IMAGES_AGENCY } from "../agency/redux/constants";
import { CREATE_CATEGORY, DELETE_CATEGORY, DELETE_IMAGE_CATEGORY, UPDATE_CATEGORY, UPLOAD_IMAGES_CATEGORY } from "../category/redux/constants";
import { CREATE_HOTEL, DELETE_HOTEL, DELETE_IMAGE_HOTEL, UPDATE_HOTEL, UPLOAD_IMAGES_HOTEL } from "../hotels/redux/constants";
import { CREATE_ROLE, DELETE_ROLE, UPDATE_ROLE } from "../role/redux/constants";
import { CREATE_STAFF, DELETE_IMAGE_STAFF, DELETE_STAFF, UPDATE_STAFF, UPLOAD_IMAGES_STAFF } from "../staff/redux/constants";
import { CREATE_SUB_CATEGORY, DELETE_IMAGE_SUB_CATEGORY, DELETE_SUB_CATEGORY, UPDATE_SUB_CATEGORY, UPLOAD_IMAGES_SUB_CATEGORY } from "../subCategory/redux/constants";
import { DELETE_TOUR, CREATE_TOUR, UPDATE_TOUR, UPLOAD_IMAGES_TOUR, DELETE_IMAGE_TOUR } from "../tours/redux/constants";
import { CREATE_USER, DELETE_IMAGE_USER, DELETE_USER, UPDATE_USER, UPLOAD_IMAGES_USER } from "../users/redux/constants";
import { COMMON_VALIDATION_ERROR, COMMON_ERROR, COMMON_REQUEST, COMMON_SUCCESS, LOGIN, USER_DATA, LOGOUT_SUCCESS, IS_DARK_MODE, LIST_DATA } from "./constants";
import storage from 'redux-persist/lib/storage';


const initialState = {
    list_data: [],
    common_error: null,
    common_success: null,
    common_validation_error: null,
    common_requesting: false,
    is_dark_mode: false,
    user: false,
    isLogged: false,
};

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        /** 
         * @param COMMON_ERROR - usage for snackbar when post request given error
         */
        case COMMON_ERROR:
            return { ...state, common_error: action.data };


        /** 
        * @param COMMON_SUCCESS - usage for snackbar when post request given success
        */
        case COMMON_SUCCESS:
            return { ...state, common_success: action.data };


        /** 
        * @param COMMON_VALIDATION_ERROR - usage for form validation error which one sended from api
        */
        case COMMON_VALIDATION_ERROR:
            return { ...state, common_validation_error: action.data };


        /** 
        * @param COMMON_REQUEST - usage for set new state - to request controlling state
        */
        case COMMON_REQUEST:
            return { ...state, common_requesting: action.data };

        /**
         * @param IS_DARK_MODE - usage for toggling dark mode state
         */
        case IS_DARK_MODE:
            return { ...state, is_dark_mode: action.data };

        /**
         * @param LOGOUT_SUCCESS - usage for setting state when logout is successful
         */
        case LOGOUT_SUCCESS:
            storage.removeItem('persist:adali-admin');
            // Cookies.remove('twj');
            return {
                ...state,
                ...initialState,
            };

        /**
         * @param USER_DATA - usage for updating user data in the state
         */
        case USER_DATA:
            return {
                ...state,
                user: action.data,
                isLogged: action.data.username,
            };

        /**
         * @param LIST_DATA - usage for updating list_data data in the state
         */
        case LIST_DATA:
            return {
                ...state,
                list_data: action.data,
            };


        /**
         * usage for setting state when a CRUD actions is performed
         */
        case CREATE_HOTEL:
        case UPDATE_HOTEL:
        case DELETE_HOTEL:
        case UPLOAD_IMAGES_HOTEL:
        case DELETE_IMAGE_HOTEL:

        case CREATE_TOUR:
        case UPDATE_TOUR:
        case DELETE_TOUR:
        case UPLOAD_IMAGES_TOUR:
        case DELETE_IMAGE_TOUR:

        case CREATE_CATEGORY:
        case UPDATE_CATEGORY:
        case DELETE_CATEGORY:
        case UPLOAD_IMAGES_CATEGORY:
        case DELETE_IMAGE_CATEGORY:

        case UPLOAD_IMAGES_SUB_CATEGORY:
        case DELETE_IMAGE_SUB_CATEGORY:
        case CREATE_SUB_CATEGORY:
        case UPDATE_SUB_CATEGORY:
        case DELETE_SUB_CATEGORY:

        case CREATE_STAFF:
        case UPDATE_STAFF:
        case DELETE_STAFF:
        case UPLOAD_IMAGES_STAFF:
        case DELETE_IMAGE_STAFF:

        case CREATE_AGENCY:
        case UPDATE_AGENCY:
        case DELETE_AGENCY:
        case UPLOAD_IMAGES_AGENCY:
        case DELETE_IMAGE_AGENCY:

        case CREATE_ROLE:
        case UPDATE_ROLE:
        case DELETE_ROLE:

        case CREATE_USER:
        case UPDATE_USER:
        case DELETE_USER:
        case UPLOAD_IMAGES_USER:
        case DELETE_IMAGE_USER:

        case LOGIN:
            return {
                ...state,
                common_requesting: true,
            };
        default:
            return state;
    }
};

export default commonReducer;
