import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, Col, Form, Grid, IconButton, Input, InputNumber, Modal, SelectPicker, Tooltip, Uploader, Whisper } from 'rsuite';
import { CustomInput, DynamicallyTag } from '../../components/CustomComponents';
import { connect, useDispatch } from 'react-redux';
import './staff.scss';
import { staffDataAction, deleteImageStaffAction, saveStaffAction, updateStaffAction, uploadImagesStaffAction } from './redux/actions';
import { getAgenciesAction } from '../agency/redux/actions';
import { GENDERS } from '../../utils/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getRolesAction } from '../role/redux/actions';
import { hasPermission } from '../../utils/helpers';

// Define your schema
const validationSchema = yup.object().shape({
    password: yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(/[a-z]/, 'Password must include at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must include at least one uppercase letter')
        .matches(/\d/, 'Password must include at least one number')
        .matches(/[@$!%*?&]/, 'Password must include at least one special character')
});

const StaffCUModal = (props) => {

    const { open, setOpen, editData, setEditData, type } = props;
    const {
        handleSubmit,
        control, reset, setValue, getValues,
        formState: { errors }
    } = useForm({
        resolver: type === "create" && yupResolver(validationSchema),
        defaultValues: {
            password: ''
        }
    });

    useEffect(() => {
        if (editData) {
            setValue("username", editData.username);
            setValue("email", editData.email);
            setValue("fullName", editData.fullName);
            setValue("agency_id", editData?.agency_id ?? "");
            setValue("role_id", editData?.role_id || "");
            setValue("gender", editData?.gender || "");
            setValue("position", editData?.position || "");
            setValue("phone_number", editData?.phone_number || "");
        }
    }, [editData])

    const [agencyItems, setAgencyItems] = useState(props.agencies_data?.data?.map(item => ({ label: item.title, value: item.id })) ?? []);
    const categoryUpdateData = () => {
        if (agencyItems.length === 0) {
            setAgencyItems(props.agencies_data?.data?.map(item => ({ label: item.title, value: item.id })) ?? []);
        }
    };


    const [roleItems, setRoleItems] = useState(props.roles_data?.data?.map(item => ({ label: item.title, value: item.id })) ?? []);
    const roleUpdateData = () => {
        if (roleItems.length === 0) {
            setRoleItems(props.roles_data?.data?.map(item => ({ label: item.title, value: item.id })) ?? []);
        }
    };

    useEffect(() => {
        if (!Boolean(props.agencies_data) && hasPermission(props.user?.role?.permissions, "agency create")) {
            props.getAgenciesAction(1, 50);
        }
        if (!Boolean(props.roles_data)) {
            props.getRolesAction(1, 50);
        }
    }, [])


    const onSubmit = data => {
        const formData = new FormData();

        Object.entries(data).forEach(([key, value]) => {
            if (key === "agency_id" && value && hasPermission(props.user?.role?.permissions, "agency create")) {
                formData.append(key, value);
            } else if (value) {
                formData.append(key, value);
            }
        })

        if (editData) {
            props.updateStaffAction(editData.id, formData, callback);
        } else {
            props.saveStaffAction(formData, callback);
        }
    };

    const callback = useCallback((data) => {
        if (data) {
            let updatedTableList = [];
            if (editData) {
                updatedTableList = props.staff_data.data?.map(item => {
                    if (item.id === editData.id) {
                        return data;
                    }
                    return item;
                })
            } else {
                updatedTableList = [...props.staff_data.data, data];
            }
            props.staffDataAction({ ...props.staff_data, data: updatedTableList, total: props.staff_data.total + 1 });
            resetFields();
        }
    }, []);

    const resetFields = () => {
        if (setEditData) {
            setEditData(false)
        }
        reset();
        setOpen(prev => ({ ...prev, [type]: false }))
    }

    const validateUsername = (value) => {
        if (!value) return "Username is required";
        if (/\s/.test(value)) return "Username cannot contain spaces";
        if (!/^[a-zA-Z0-9_]+$/.test(value)) return "Username can only contain letters, numbers, and underscores";
        if (value.length < 3) return "Username must be at least 3 characters long";
        if (value.length > 20) return "Username must be less than 20 characters";
        return true;
    };

    return (
        <Modal backdrop={"static"} size={"lg"} open={open} onClose={() => resetFields()}>
            <Modal.Header>
                <Modal.Title>Agenta Lideri Ekle</Modal.Title>
            </Modal.Header>

            <Form fluid onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Grid fluid className='tour_form'>
                        {/* username */}
                        <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                            <Controller
                                name="username"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Kullanıcı adı"}
                                        required={type === "create"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Whisper trigger="focus" speaker={<Tooltip>Gerekli</Tooltip>}>
                                                <Input value={field?.value} onChange={field?.onChange} />
                                            </Whisper>
                                        }
                                    />
                                )}
                                rules={{ validate: type === "create" && validateUsername }}
                            />
                        </Col>
                        {/* email */}
                        <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"E-posta"}
                                        required={type === "create"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Whisper trigger="focus" speaker={<Tooltip>Gerekli</Tooltip>}>
                                                <Input value={field?.value} onChange={field?.onChange} />
                                            </Whisper>
                                        }
                                    />
                                )}
                                rules={{
                                    required: type === "create" && "Gerekli",
                                    pattern: {
                                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                        message: "Geçerli bir e-posta adresi girin"
                                    }
                                }}
                            />
                        </Col>
                        {/* password */}
                        <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                            <Controller
                                name="password"
                                control={control}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Şifre"}
                                        required={type === "create"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Whisper trigger="focus" speaker={<Tooltip>Gerekli</Tooltip>}>
                                                <Input type='password' {...field} />
                                            </Whisper>
                                        }
                                    />
                                )}
                                rules={{ required: type === "create" && "Gerekli" }}
                            />
                        </Col>
                        {/* fullName */}
                        <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                            <Controller
                                name="fullName"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"İsim Soyisim"}
                                        required={type === "create"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Whisper trigger="focus" speaker={<Tooltip>Gerekli</Tooltip>}>
                                                <Input value={field?.value} onChange={field?.onChange} />
                                            </Whisper>
                                        }
                                    />
                                )}
                                rules={{ required: type === "create" && "Gerekli" }}
                            />
                        </Col>
                        {/* agency_id */}
                        {hasPermission(props.user?.role?.permissions, "agency create") &&
                            <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                                <Controller
                                    name="agency_id"
                                    control={control}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <CustomInput
                                            title={"Agenta"}
                                            required={type === "create"}
                                            error={errors[field?.name]?.message}
                                            children={
                                                <SelectPicker
                                                    data={agencyItems}
                                                    value={field?.value}
                                                    onChange={field?.onChange}
                                                    onSearch={categoryUpdateData}
                                                    onOpen={categoryUpdateData}
                                                    style={{ width: 300 }}
                                                />
                                            }
                                        />
                                    )}
                                    rules={{ required: type === "create" && "Gerekli" }}
                                />
                            </Col>
                        }
                        {/* role_id */}
                        <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                            <Controller
                                name="role_id"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Rol"}
                                        required={type === "create"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <SelectPicker
                                                data={roleItems}
                                                value={field?.value}
                                                onChange={field?.onChange}
                                                style={{ width: 300 }}
                                                onSearch={roleUpdateData}
                                                onOpen={roleUpdateData}
                                            />
                                        }
                                    />
                                )}
                                rules={{ required: type === "create" && "Gerekli" }}
                            />
                        </Col>
                        {/* phone_number */}
                        <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                            <Controller
                                name="phone_number"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Tel Numarası"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <InputNumber minLength={1} maxLength={15} value={field?.value} onChange={field?.onChange} />
                                        }
                                    />
                                )}
                                rules={{ maxLength: 15, minLength: 1 }}
                            />
                        </Col>
                        {/* gender */}
                        <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                            <Controller
                                name="gender"
                                control={control}
                                defaultValue={'Erkek'}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Cinsiyet"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <SelectPicker
                                                data={GENDERS.map(item => ({ label: item, value: item }))}
                                                value={field?.value}
                                                onChange={field?.onChange}
                                                style={{ width: 300 }}
                                            />
                                        }
                                    />
                                )}
                            />
                        </Col>
                        {/* position */}
                        <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                            <Controller
                                name="position"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Pozisyon"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Input value={field?.value} onChange={field?.onChange} />
                                        }
                                    />
                                )}
                            />
                        </Col>

                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" appearance="primary">
                        Kaydet
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

const mapStateToProps = state => ({
    common_requesting: state.commonReducer.common_requesting,
    staff_data: state.staffReducer.staff_data,
    agencies_data: state.agencyReducer.agencies_data,
    roles_data: state.roleReducer.roles_data,
    user: state.commonReducer.user,
});

const mapDispatchToProps = dispatch => ({
    getRolesAction: (page, limit) => dispatch(getRolesAction(page, limit)),
    getAgenciesAction: (page, limit) => dispatch(getAgenciesAction(page, limit)),
    saveStaffAction: (body, callback) => dispatch(saveStaffAction(body, callback)),
    updateStaffAction: (id, body, callback) => dispatch(updateStaffAction(id, body, callback)),
    staffDataAction: (data) => dispatch(staffDataAction(data)),
    deleteImageStaffAction: (id, body, callback) => dispatch(deleteImageStaffAction(id, body, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffCUModal)