import {
    AGENCIES_DATA,
} from "./constants";

const initialState = {
    agencies_data: null,
};

const agencyReducer = (state = initialState, action) => {
    switch (action.type) {

        case AGENCIES_DATA:
            return {
                ...state,
                agencies_data: action.data,
            };
        default:
            return state;
    }
};

export default agencyReducer;
