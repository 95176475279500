import React from 'react'
import { connect } from 'react-redux';
import { Col, Grid, InlineEdit, Input, Row, Stack } from 'rsuite';

const TextArea = React.forwardRef((props, ref) => {
    return <Input as="textarea" ref={ref} {...props} />;
});

const Field = ({ label, as: Component, value, defaultValue, ...rest }) => {
    return (
        <Stack direction="row">
            <label style={{ width: 120, display: 'inline-block', color: 'var(--rs-text-secondary)' }}>
                {label}
            </label>
            <InlineEdit placeholder="Click to edit ..." defaultValue={defaultValue} value={value}>
                <Component style={{ width: 300 }} {...rest} />
            </InlineEdit>
        </Stack>
    );
};

const AgencyProfile = (props) => {
    return (
        <div>
            <Row>
                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Stack direction="column" alignItems="flex-start" spacing={10}>
                        <Field label="Başlık" as={Input} value={props.user?.agency?.title} />
                        <Field label="E-posta" as={Input} value={props.user.agency.email} />
                        {props.user?.agency?.contact_number && <Field label="Iletişim Numara" as={Input} value={props.user.agency.contact_number} />}
                        {props.user?.agency?.address && <Field label="Adres" as={Input} value={props.user.agency.address} />}
                        {props.user?.agency?.description && <Field label="Tanıtım" as={TextArea} row={5} value={props.user.agency.description} />}
                        {/* <Field label="Bio" as={TextArea} defaultValue="I'm a software engineer." row={5} />
                        <Field
                            label="Date of Birth"
                            as={DatePicker}
                            defaultValue={new Date('1988-08-08')}
                            format="MMMM dd, yyyy"
                        />
                        <Field
                            label="Skills"
                            as={TagPicker}
                            data={skills}
                            defaultValue={['React', 'TypeScript', 'Node.js']}
                        />
                        <Field label="Skill Level" as={Slider} defaultValue={50} /> */}
                    </Stack>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <img alt='profile' src={props.user?.agency?.thumbnail ?? ""} style={{ borderRadius: '10px', maxWidth: '100%', maxHeight: '400px', objectFit: 'cover' }} />
                    </div>
                </Col>
            </Row>
        </div>
    )
}
const mapStateToProps = state => ({
    common_requesting: state.commonReducer.common_requesting,
    user: state.commonReducer.user,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AgencyProfile)