import { forwardRef, useEffect, useRef, useState } from "react";
import { Button, ButtonToolbar, Col, Container, Dropdown, Form, Grid, IconButton, Input, Loader, Modal, Notification, Popover, Tag, TagGroup, Uploader } from "rsuite";
import FormGroup from "rsuite/esm/FormGroup";
import PlusIcon from '@rsuite/icons/Plus';
import { CATEGORY_URL } from "../services/constants";
import { useSelector } from "react-redux";
import CloseIcon from '@rsuite/icons/Close';
import Swal from "sweetalert2";
import { hasPermission } from "../utils/helpers";

export const CustomInput = ({
    title, titleClassName, titleStyle, required, autoFocus, error, children, inputWidth
}) => {
    return (

        <FormGroup autoFocus={autoFocus} style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: inputWidth ?? '100%', marginBottom: '10px' }}>
            {title && <span className={titleClassName ?? "search_lbl"} style={{ ...titleStyle }}>{title} {required &&
                <span style={{ color: 'red' }}> &nbsp;*</span>}</span>}
            {children}
            <Form.ErrorMessage show={!!error} placement="bottomStart">
                {error}
            </Form.ErrorMessage>
        </FormGroup>
    );
}

export const DynamicallyTag = ({ tags, setTags, inputWidth }) => {
    const [typing, setTyping] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [draggedIndex, setDraggedIndex] = useState(null);

    const removeTag = tag => {
        const nextTags = tags.filter(item => item !== tag);
        setTags(nextTags);
    };

    const addTag = () => {
        if (inputValue.trim()) {
            const nextTags = [...tags, inputValue];
            setTags(nextTags);
            setInputValue('');
            setTyping(false);
        }
    };

    const handleButtonClick = () => {
        setTyping(true);
    };

    // Native drag-and-drop events
    const handleDragStart = (index) => {
        setDraggedIndex(index);
    };

    const handleDragOver = (e) => {
        e.preventDefault(); // Prevent the default behavior to allow dropping
    };

    const handleDrop = (dropIndex) => {
        if (draggedIndex === dropIndex) return;

        const updatedTags = [...tags];
        const [draggedTag] = updatedTags.splice(draggedIndex, 1); // Remove the dragged tag
        updatedTags.splice(dropIndex, 0, draggedTag); // Insert it at the dropped index

        setTags(updatedTags);
        setDraggedIndex(null); // Clear the dragged index after dropping
    };

    const renderInput = () => {
        if (typing) {
            return (
                <div className="dynamically_tag">
                    <Input
                        className="tag-input"
                        size="xs"
                        style={{ width: inputWidth ?? 90 }}
                        value={inputValue}
                        autoFocus
                        onChange={setInputValue}
                        onBlur={addTag}
                        onPressEnter={addTag}
                    />
                </div>
            );
        }

        return (
            <div className="dynamically_tag">
                <IconButton
                    className="tag-add-btn"
                    onClick={handleButtonClick}
                    icon={<PlusIcon />}
                    appearance="ghost"
                    size="xs"
                />
            </div>
        );
    };

    return (
        <TagGroup style={{ paddingTop: '5px' }}>
            {tags.map((item, index) => (
                <Tag
                    key={index}
                    closable
                    draggable // Enable native drag-and-drop
                    onDragStart={() => handleDragStart(index)} // Start dragging
                    onDragOver={handleDragOver} // While dragging over another tag
                    onDrop={() => handleDrop(index)} // When dropped
                    onClose={() => removeTag(item)} // Close functionality
                    style={{ cursor: 'move' }} // Optional: cursor style for better UX
                >
                    {item}
                </Tag>
            ))}
            {renderInput()}
        </TagGroup>
    );
};


export const CustomToaster = forwardRef(({ type, confirmHandler, header, description, value, style, }, ref) => {
    return (
        <Notification ref={ref} style={{ minWidth: '500px', ...style }} type={type ?? "warning"} header={header ?? `${type?.toUpperCase()}!`} closable>
            <p>{description ?? "Veri silinecek"}</p>
            <hr />
            <ButtonToolbar>
                <Button appearance="primary" onClick={() => confirmHandler(value)}>Sil</Button>
                <Button appearance="default" onClick={() => confirmHandler(false)}>Kapat</Button>
            </ButtonToolbar>
        </Notification>
    )
});


export const SwalInfoPopap = ({ position = "top-end", icon = "success",
    title = "Değişiklikleriniz kaydedildi", html,
    showConfirmButton = false, timer = 2500, text,
    thenFunc
}) => {
    return (
        Swal.fire({
            position: position,
            icon: icon,
            title: title,
            showConfirmButton: showConfirmButton,
            timer: timer,
            text: text,
            html: html,
        }).then((result) => {
            if (thenFunc) {
                thenFunc(result)
            }
        })
    )
}

export const SwalDeleteConfirm = ({ onConfirm, onCancel, }) => {
    return (
        Swal.fire({
            title: "Emin misin?",
            text: "Bunu geri alamazsınız!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Evet, silin!"
        }).then((result) => {
            if (result.isConfirmed) {
                onConfirm()
            } else if (result.isDenied) {
                onCancel()
            }
        })

    )
}

export const SwalConfirm = ({ title = "Emin misin?", text = "Bunu geri alamazsınız!",
    icon = "warning", showCancelButton = true, confirmButtonColor = "#3085d6",
    cancelButtonColor = "#d33", confirmButtonText = "Evet!", onConfirm, onCancel, }) => {
    return (
        Swal.fire({
            title: title,
            text: text,
            icon: icon,
            showCancelButton: showCancelButton,
            confirmButtonColor: confirmButtonColor,
            cancelButtonColor: cancelButtonColor,
            confirmButtonText: confirmButtonText
        }).then((result) => {
            if (result.isConfirmed) {
                onConfirm()
            } else if (result.isDenied) {
                onCancel()
            }
        })

    )
}



export const renderMenu = (rowData, callback, hasImages, hasThumbnail, perEntity) => ({ onClose, left, top, className }, ref) => {
    const handleSelect = eventKey => {
        callback(eventKey, rowData);
        onClose();
    };
    return (
        <Popover ref={ref} className={className} style={{ left, top }} full>
            <Dropdown.Menu onSelect={handleSelect}>
                <PermissionsWrapper permission={`${perEntity} update`}>
                    {hasImages && <Dropdown.Item eventKey={1}>Resmler ekle</Dropdown.Item>}
                    {hasThumbnail && <Dropdown.Item eventKey={2}>Logo ekle</Dropdown.Item>}
                    <Dropdown.Item eventKey={3}>Düzenle</Dropdown.Item>
                </PermissionsWrapper>
                <PermissionsWrapper permission={`${perEntity} delete`}>
                    <Dropdown.Item eventKey={4}>Sil</Dropdown.Item>
                </PermissionsWrapper>
            </Dropdown.Menu>
        </Popover>
    );
};

export const ImageUploadModal = ({ open, setOpen, type, rowData, uploadFunc, deleteFunc, updateRowDataFunc, tableList, loading, setEditData }) => {

    const [images, setImages] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const uploader = useRef(null);
    useEffect(() => {
        if (rowData) {
            if (type === "images" && Boolean(rowData?.images)) {
                let rowImages = rowData.images?.split("->");
                setUploadedFiles(rowImages?.length > 0 ? rowImages : []);
            } else if (type === "thumbnail" && Boolean(rowData?.thumbnail || rowData?.user?.thumbnail)) {
                setUploadedFiles([rowData.thumbnail || rowData.user.thumbnail]);
            }
        }
    }, [rowData])

    const uploadHandler = (files) => {
        if (type === "thumbnail" && files.length > 1) {
            files = [files[files.length - 1]];
        }
        setImages(files);
    }

    const onRemoveHandler = (url, index) => {
        SwalDeleteConfirm({
            onConfirm: (e) => {
                if (rowData.id) {
                    deleteFunc(rowData.id, { imageUrl: url, type: type }, (data) => {
                        if (data) {
                            setUploadedFiles(prev => prev.filter((e, k) => k !== index));
                        }
                    })
                }
            },
            onCancel: (e) => { debugger }
        })
    }
    const resetFields = () => {
        setImages([]);
        setUploadedFiles([]);
        uploader.current = null;
        if (setEditData) {
            setEditData(false)
        }
        setOpen(prev => ({ ...prev, [type]: false }))
    }

    const onSubmit = () => {
        if (type && images?.length > 0) {
            const formData = new FormData();
            images.forEach((file) => {
                formData.append('files', file.blobFile, file.name);
            });
            formData.append("type", type);
            uploadFunc(rowData.id, formData, (data) => {
                let updatedList = tableList.data.map(item => {
                    if (item.id === data?.data?.id) {
                        return data.data
                    }
                    return item;
                })
                if (data?.data?.id) {
                    updateRowDataFunc({ ...tableList, data: updatedList })
                    resetFields();
                }
            })
        }
    }


    return (
        <Modal backdrop={"static"} size={"md"} open={open} onClose={() => resetFields()}>
            <Modal.Header>
                <Modal.Title>Resm Ekle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Grid fluid className='tour_form'>
                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Uploader
                            fileList={images}
                            action={`url/img-upload/${rowData.id}`}
                            autoUpload={false}
                            multiple={type === "images" ? true : false}
                            draggable
                            onChange={uploadHandler}
                            ref={uploader}
                            disabled={loading}
                            onRemove={type === "thumbnail" ? () => setImages([]) : () => { }}
                        >
                            <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span>{type === "images" ? "Yeni Resmleri ekleyin" : " Yeni Logo ekleyin"}</span>
                            </div>
                        </Uploader>
                    </Col>
                    {uploadedFiles?.length > 0 ? (
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <h3>{type === "images" ? "Var olan resimler" : "Mevcut Logo"}</h3>
                            <div className="img_removable">
                                {uploadedFiles.map((val, key) => (
                                    <div
                                        key={key}
                                        className="img_removable_item"
                                    >
                                        <img loading='lazy' style={{ borderRadius: '5px', maxWidth: '400px', maxHeight: '200px', objectFit: "cover" }} src={val} />
                                        <IconButton circle className="img_removable_item_icon" onClick={() => onRemoveHandler(val, key)} icon={<CloseIcon />} />
                                        {loading && <Loader backdrop content="loading..." vertical />}
                                    </div>
                                ))}
                            </div>
                        </Col>
                    ) : null}
                </Grid>
            </Modal.Body>
            <Modal.Footer>
                <Button appearance="primary" onClick={() => onSubmit()} loading={loading}>
                    Kaydet
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export const PermissionsWrapper = ({ permission, children }) => {
    const user = useSelector((state) => state.commonReducer.user);
    return hasPermission(user.role.permissions, permission) ? children : null;
};