import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, Col, Form, Grid, IconButton, Input, InputNumber, Modal, SelectPicker, Tooltip, Uploader, Whisper } from 'rsuite';
import { CustomInput, DynamicallyTag } from '../../components/CustomComponents';
import { connect, useDispatch } from 'react-redux';
import './agency.scss';
import { agenciesDataAction, deleteImageAgencyAction, saveAgencyAction, updateAgencyAction, uploadImagesAgencyAction } from './redux/actions';
import { isValidIBAN } from 'ibantools';


const AgencyCUModal = (props) => {

    const { open, setOpen, editData, setEditData, type } = props;
    const {
        handleSubmit,
        control, reset, setValue, getValues,
        formState: { errors }
    } = useForm();

    useEffect(() => {
        if (editData) {
            setValue("title", editData?.title || "");
            setValue("email", editData?.email || "");
            setValue("address", editData?.address || "");
            setValue("contact_number", editData?.contact_number || "");
            setValue("iban", editData?.iban || "");
            setValue("description", editData?.description || "");
        }
    }, [editData])


    const onSubmit = data => {
        const formData = new FormData();

        Object.entries(data).forEach(([key, value]) => {
            if (key === "iban" && value) {
                const trimmedValue = value.replace(/\s+/g, '');
                formData.append(key, trimmedValue);
            } else if (value) {
                formData.append(key, value);
            }
        })

        if (editData) {
            props.updateAgencyAction(editData.id, formData, callback);
        } else {
            props.saveAgencyAction(formData, callback);
        }
    };

    const callback = useCallback((data) => {
        if (data) {
            let updatedTableList = [];
            if (editData) {
                updatedTableList = props.agencies_data?.data?.map(item => {
                    if (item.id === editData.id) {
                        return data;
                    }
                    return item;
                })
            } else {
                updatedTableList = [...props.agencies_data?.data, data];
            }
            props.agenciesDataAction({ ...props.agencies_data, data: updatedTableList, total: props.agencies_data?.total + 1 });
            resetFields();
        }
    }, []);

    const resetFields = () => {
        if (setEditData) {
            setEditData(false)
        }
        reset();
        setOpen(prev => ({ ...prev, [type]: false }))
    }

    const validateIBAN = (value) => {
        if (!value) return true; // Skip validation if empty
        const trimmedValue = value.replace(/\s+/g, '');
        if (!isValidIBAN(trimmedValue)) return "Invalid IBAN";
        const countryCode = trimmedValue.slice(0, 2);
        const allowedCountries = ['DE', 'TR'];
        if (!allowedCountries.includes(countryCode)) return "IBAN from unsupported country";
        return true;
    };

    return (
        <Modal backdrop={"static"} size={"lg"} open={open} onClose={() => resetFields()}>
            <Modal.Header>
                <Modal.Title>Agenta Ekle</Modal.Title>
            </Modal.Header>

            <Form fluid onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Grid fluid className='tour_form'>
                        {/* title */}
                        <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                            <Controller
                                name="title"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Başlık"}
                                        required={!editData}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Whisper trigger="focus" speaker={<Tooltip>Gerekli</Tooltip>}>
                                                <Input value={field?.value} onChange={field?.onChange} />
                                            </Whisper>
                                        }
                                    />
                                )}
                                rules={{ required: !editData && "Gerekli" }}
                            />
                        </Col>
                        {/* email */}
                        <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"E-posta"}
                                        required={!editData}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Input value={field?.value} onChange={field?.onChange} />
                                        }
                                    />
                                )}
                                rules={{
                                    required: !editData && "Gerekli",
                                    pattern: {
                                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                        message: "Geçerli bir e-posta adresi girin"
                                    }
                                }}
                            />
                        </Col>
                        {/* contact_number */}
                        <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                            <Controller
                                name="contact_number"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Tel numara"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <InputNumber minLength={1} maxLength={15} value={field?.value} onChange={field?.onChange} />
                                        }
                                    />
                                )}
                                rules={{ minLength: 1, maxLength: 15 }}
                            />
                        </Col>
                        {/* address */}
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                            <Controller
                                name="address"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Adres"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Input value={field?.value} onChange={field?.onChange} />
                                        }
                                    />
                                )}
                            />
                        </Col>
                        {/* iban */}
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                            <Controller
                                name="iban"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Iban"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Input value={field?.value} onChange={field?.onChange} />
                                        }
                                    />
                                )}
                                rules={{ validate: validateIBAN }}
                            />
                        </Col>
                        {/* description */}
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Controller
                                name="description"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Tanıtım"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Input as={"textarea"} rows={4} value={field?.value} onChange={field?.onChange} />
                                        }
                                    />
                                )}
                            />
                        </Col>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" appearance="primary">
                        Kaydet
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

const mapStateToProps = state => ({
    common_requesting: state.commonReducer.common_requesting,
    agencies_data: state.agencyReducer.agencies_data,
});

const mapDispatchToProps = dispatch => ({
    saveAgencyAction: (body, callback) => dispatch(saveAgencyAction(body, callback)),
    updateAgencyAction: (id, body, callback) => dispatch(updateAgencyAction(id, body, callback)),
    agenciesDataAction: (data) => dispatch(agenciesDataAction(data)),
    deleteImageAgencyAction: (id, body, callback) => dispatch(deleteImageAgencyAction(id, body, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(AgencyCUModal)