import { instance, setParams } from "../conf/xhr";
import { AGENCY_LEADER_URL, AGENCY_URL, BANNER_URL, CATEGORY_URL, EDUCATION_URL, HOTEL_URL, LOGIN, LOGOUT, PERMISSION_URL, ROLE_URL, STAFF, SUB_CATEGORY_URL, TOUR_URL, TRANSFER_URL, USER_URL } from "./constants";
import Cookies from 'js-cookie';

const CURR_TOKEN = Cookies.get("twj");

//login
export const login = async (body) => {
    return await instance.post(LOGIN, body);
};

export const logout = async (token) => {
    return await instance.get(LOGOUT, setParams("", token));
};

// Category
export const createCategory = async (body) => {
    return await instance.post(`${CATEGORY_URL}/create`, body, setParams("", CURR_TOKEN));
};
export const updateCategory = async (id, body) => {
    return await instance.post(`${CATEGORY_URL}/update/${id}`, body, setParams("", CURR_TOKEN));
};
export const imagesUploadCategory = async (id, body) => {
    return await instance.post(`${CATEGORY_URL}/img-upload/${id}`, body, setParams("", CURR_TOKEN));
};
export const imageDeleteCategory = async (id, body) => {
    return await instance.delete(`${CATEGORY_URL}/img-delete/${id}`, setParams(body, CURR_TOKEN, "delete"));
};
export const deleteCategory = async (id) => {
    return await instance.delete(`${CATEGORY_URL}/delete/${id}`, setParams("", CURR_TOKEN));
};
export const getCategories = async (page, limit) => {
    return await instance.get(CATEGORY_URL, setParams({ page, limit }));
};


// SubCategory
export const createSubCategory = async (body) => {
    return await instance.post(`${SUB_CATEGORY_URL}/create`, body, setParams("", CURR_TOKEN));
};
export const updateSubCategory = async (id, body) => {
    return await instance.post(`${SUB_CATEGORY_URL}/update/${id}`, body, setParams("", CURR_TOKEN));
};
export const deleteSubCategory = async (id) => {
    return await instance.delete(`${SUB_CATEGORY_URL}/delete/${id}`, setParams("", CURR_TOKEN));
};
export const getSubCategories = async (page, limit) => {
    return await instance.get(SUB_CATEGORY_URL, setParams({ page, limit }));
};
export const imagesUploadSubCategory = async (id, body) => {
    return await instance.post(`${SUB_CATEGORY_URL}/img-upload/${id}`, body, setParams("", CURR_TOKEN));
};
export const imageDeleteSubCategory = async (id, body) => {
    return await instance.delete(`${SUB_CATEGORY_URL}/img-delete/${id}`, setParams(body, CURR_TOKEN, "delete"));
};


// Tour
export const createTour = async (body) => {
    return await instance.post(`${TOUR_URL}/create`, body, setParams("", CURR_TOKEN));
};
export const updateTour = async (id, body) => {
    return await instance.post(`${TOUR_URL}/update/${id}`, body, setParams("", CURR_TOKEN));
};
export const deleteTour = async (id) => {
    return await instance.delete(`${TOUR_URL}/delete/${id}`, setParams("", CURR_TOKEN));
};
export const getTours = async (page, limit) => {
    return await instance.get(TOUR_URL, setParams({ page, limit }, CURR_TOKEN));
};
export const getToursByAgency = async (queryParams) => {
    return await instance.get(`${TOUR_URL}/by-agency`, setParams(queryParams, CURR_TOKEN));
};
export const getTourByName = async (name) => {
    return await instance.get(`${TOUR_URL}/detail`, setParams({ name }));
};
export const imagesUploadTour = async (id, body) => {
    return await instance.post(`${TOUR_URL}/img-upload/${id}`, body, setParams("", CURR_TOKEN));
};
export const imageDeleteTour = async (id, body) => {
    return await instance.delete(`${TOUR_URL}/img-delete/${id}`, setParams(body, CURR_TOKEN, "delete"));
};

// Hotel
export const createHotel = async (body) => {
    return await instance.post(`${HOTEL_URL}/create`, body, setParams("", CURR_TOKEN));
};
export const updateHotel = async (id, body) => {
    return await instance.post(`${HOTEL_URL}/update/${id}`, body, setParams("", CURR_TOKEN));
};
export const deleteHotel = async (id) => {
    return await instance.delete(`${HOTEL_URL}/delete/${id}`, setParams("", CURR_TOKEN));
};
export const getHotels = async (page, limit) => {
    return await instance.get(HOTEL_URL, setParams({ page, limit }, CURR_TOKEN));
};
export const getHotelsByAgency = async (queryParams) => {
    return await instance.get(`${HOTEL_URL}/by-agency`, setParams(queryParams, CURR_TOKEN));
};
export const getHotelByName = async (name) => {
    return await instance.get(`${HOTEL_URL}/detail`, setParams({ name }));
};
export const imagesUploadHotel = async (id, body) => {
    return await instance.post(`${HOTEL_URL}/img-upload/${id}`, body, setParams("", CURR_TOKEN));
};
export const imageDeleteHotel = async (id, body) => {
    return await instance.delete(`${HOTEL_URL}/img-delete/${id}`, setParams(body, CURR_TOKEN, "delete"));
};


// Agency
export const createAgency = async (body) => {
    return await instance.post(`${AGENCY_URL}/create`, body, setParams("", CURR_TOKEN));
};
export const updateAgency = async (id, body) => {
    return await instance.post(`${AGENCY_URL}/update/${id}`, body, setParams("", CURR_TOKEN));
};
export const imagesUploadAgency = async (id, body) => {
    return await instance.post(`${AGENCY_URL}/img-upload/${id}`, body, setParams("", CURR_TOKEN));
};
export const imageDeleteAgency = async (id, body) => {
    return await instance.delete(`${AGENCY_URL}/img-delete/${id}`, setParams(body, CURR_TOKEN, "delete"));
};
export const deleteAgency = async (id) => {
    return await instance.delete(`${AGENCY_URL}/delete/${id}`, setParams("", CURR_TOKEN));
};
export const getAgencies = async (page, limit) => {
    return await instance.get(AGENCY_URL, setParams({ page, limit }, CURR_TOKEN));
};


// Staff
export const createStaff = async (body) => {
    return await instance.post(`${STAFF}/create`, body, setParams("", CURR_TOKEN));
};
export const updateStaff = async (id, body) => {
    return await instance.post(`${STAFF}/update/${id}`, body, setParams("", CURR_TOKEN));
};
export const deleteStaff = async (id) => {
    return await instance.delete(`${STAFF}/delete/${id}`, setParams("", CURR_TOKEN));
};
export const getStaff = async (page, limit) => {
    return await instance.get(STAFF, setParams({ page, limit }, CURR_TOKEN));
};
export const imagesUploadStaff = async (id, body) => {
    return await instance.post(`${STAFF}/img-upload/${id}`, body, setParams("", CURR_TOKEN));
};
export const imageDeleteStaff = async (id, body) => {
    return await instance.delete(`${STAFF}/img-delete/${id}`, setParams(body, CURR_TOKEN, "delete"));
};

// Role
export const createRole = async (body) => {
    return await instance.post(`${ROLE_URL}/create`, body, setParams("", CURR_TOKEN));
};
export const updateRole = async (id, body) => {
    return await instance.post(`${ROLE_URL}/update/${id}`, body, setParams("", CURR_TOKEN));
};
export const deleteRole = async (id) => {
    return await instance.delete(`${ROLE_URL}/delete/${id}`, setParams("", CURR_TOKEN));
};
export const getRoles = async (page, limit) => {
    return await instance.get(ROLE_URL, setParams({ page, limit }, CURR_TOKEN));
};

export const getPermissions = async () => {
    return await instance.get(PERMISSION_URL, setParams("", CURR_TOKEN));
};
// User
export const createUser = async (body) => {
    return await instance.post(`${USER_URL}/create`, body, setParams("", CURR_TOKEN));
};
export const updateUser = async (id, body) => {
    return await instance.post(`${USER_URL}/update/${id}`, body, setParams("", CURR_TOKEN));
};
export const imagesUploadUser = async (id, body) => {
    return await instance.post(`${USER_URL}/img-upload/${id}`, body, setParams("", CURR_TOKEN));
};
export const imageDeleteUser = async (id, body) => {
    return await instance.delete(`${USER_URL}/img-delete/${id}`, setParams(body, CURR_TOKEN, "delete"));
};
export const deleteUser = async (id) => {
    return await instance.delete(`${USER_URL}/delete/${id}`, setParams("", CURR_TOKEN));
};
export const getUsers = async (page, limit) => {
    return await instance.get(USER_URL, setParams({ page, limit }, CURR_TOKEN));
};


// Banner
export const createBanner = async (body) => {
    return await instance.post(`${BANNER_URL}/create`, body, setParams("", CURR_TOKEN));
};
export const updateBanner = async (id, body) => {
    return await instance.post(`${BANNER_URL}/update/${id}`, body, setParams("", CURR_TOKEN));
};
export const imagesUploadBanner = async (id, body) => {
    return await instance.post(`${BANNER_URL}/img-upload/${id}`, body, setParams("", CURR_TOKEN));
};
export const imageDeleteBanner = async (id, body) => {
    return await instance.delete(`${BANNER_URL}/img-delete/${id}`, setParams(body, CURR_TOKEN, "delete"));
};
export const deleteBanner = async (id) => {
    return await instance.delete(`${BANNER_URL}/delete/${id}`, setParams("", CURR_TOKEN));
};
export const getBanners = async (page, limit) => {
    return await instance.get(BANNER_URL, setParams({ page, limit }));
};

// Transfer
export const createTransfer = async (body) => {
    return await instance.post(`${TRANSFER_URL}/create`, body, setParams("", CURR_TOKEN));
};
export const updateTransfer = async (id, body) => {
    return await instance.post(`${TRANSFER_URL}/update/${id}`, body, setParams("", CURR_TOKEN));
};
export const deleteTransfer = async (id) => {
    return await instance.delete(`${TRANSFER_URL}/delete/${id}`, setParams("", CURR_TOKEN));
};


// Education
export const createEducation = async (body) => {
    return await instance.post(`${EDUCATION_URL}/create`, body, setParams("", CURR_TOKEN));
};
export const updateEducation = async (id, body) => {
    return await instance.post(`${EDUCATION_URL}/update/${id}`, body, setParams("", CURR_TOKEN));
};
export const deleteEducation = async (id) => {
    return await instance.delete(`${EDUCATION_URL}/delete/${id}`, setParams("", CURR_TOKEN));
};