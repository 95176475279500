import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, CheckTree, Col, Form, Grid, IconButton, Input, InputNumber, Modal, Row, SelectPicker, Tooltip, Uploader, Whisper } from 'rsuite';
import { CustomInput, DynamicallyTag } from '../../components/CustomComponents';
import { connect, useDispatch } from 'react-redux';
import './role.scss';
import { getPermissionsAction, rolesDataAction, saveRoleAction, updateRoleAction, } from './redux/actions';
import {
    MdOutlineKeyboardArrowDown,
    MdOutlineKeyboardArrowRight,
    MdFilePresent,
    MdFolder
} from 'react-icons/md';

const TreeNode = ({ children, ...rest }) => {
    return (
        <div {...rest} style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
            {children}
        </div>
    );
};


const RolesCUModal = (props) => {

    const { open, setOpen, editData, setEditData, type } = props;
    const {
        handleSubmit,
        control, reset, setValue, getValues,
        formState: { errors }
    } = useForm();

    const [permissions, setPermissions] = useState([])
    const [checkedPermissions, setCheckedPermissions] = useState([])

    useEffect(() => {
        if (editData) {
            setValue("title", editData?.title || "");
            if (editData.permissions?.length > 0) {
                setCheckedPermissions(transformPermissions(editData.permissions));
            }
        }
    }, [editData])

    useEffect(() => {
        props.getPermissionsAction(setPermissionsCallback)
    }, [])

    const setPermissionsCallback = (data) => {
        if (data?.length > 0) {
            setPermissions(transformPermissions(data));
        }
    };

    const transformPermissions = (permissions) => {
        const reducedPermissions = {};

        // Iterate through each permission
        permissions.forEach(permission => {
            const { name, id } = permission;

            // Extract the entity and action from the name
            const lastSpaceIndex = name.lastIndexOf(" ");
            const entityName = name.substring(0, lastSpaceIndex).trim(); // Get the entity name
            const actionName = name.substring(lastSpaceIndex + 1).trim(); // Get the action name

            // Initialize the entity in reducedPermissions if not already done
            if (!reducedPermissions[entityName]) {
                reducedPermissions[entityName] = {
                    label: entityName.charAt(0).toUpperCase() + entityName.slice(1), // Capitalize entity name for label
                    value: [],
                    children: []
                };
            }

            // Add the ID to the value array for the entity
            reducedPermissions[entityName].value.push(id);

            // Add the action as a child object
            reducedPermissions[entityName].children.push({
                label: actionName.charAt(0).toUpperCase() + actionName.slice(1), // Capitalize action name for label
                value: id
            });
        });

        // Convert the object to an array
        return Object.values(reducedPermissions);
    };


    const onSubmit = data => {
        const formData = new FormData();
        formData.append("title", data.title);

        if (checkedPermissions?.length > 0) {
            checkedPermissions.flat().forEach(perId => {
                formData.append("permissions[]", perId);
            })
        }

        if (editData) {
            props.updateRoleAction(editData.id, formData, callback);
        } else {
            props.saveRoleAction(formData, callback);
        }
    };

    const callback = useCallback((data) => {
        if (data) {
            let updatedTableList = [];
            if (editData) {
                updatedTableList = props.roles_data.data?.map(item => {
                    if (item.id === editData.id) {
                        return data;
                    }
                    return item;
                })
            } else {
                updatedTableList = [...props.roles_data.data, data];
            }
            props.rolesDataAction({ ...props.roles_data, data: updatedTableList, total: props.roles_data.total + 1 });
            resetFields();
        }
    }, []);

    const resetFields = () => {
        if (setEditData) {
            setEditData(false)
        }
        reset();
        setOpen(prev => ({ ...prev, [type]: false }))
    }

    const checkTreeHandler = (value) => {
        setCheckedPermissions(value);
    }
    
    return (
        <Modal backdrop={"static"} size={"lg"} open={open} onClose={() => resetFields()}>
            <Modal.Header>
                <Modal.Title>Rol Ekle</Modal.Title>
            </Modal.Header>

            <Form fluid onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Grid fluid>
                        <Row>
                            <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24} className='permission_sidebar'>
                                <CheckTree
                                    data={permissions}
                                    height={'fit-content'}
                                    defaultExpandAll
                                    showIndentLine
                                    value={checkedPermissions}
                                    onChange={checkTreeHandler}
                                    renderTreeNode={treeNode => {
                                        return (
                                            <TreeNode>
                                                {treeNode.children ? <MdFolder /> : <MdFilePresent />}
                                                {treeNode.label}
                                            </TreeNode>
                                        );
                                    }}
                                    renderTreeIcon={(treeNode, expanded) => {
                                        if (treeNode.children) {
                                            return expanded ? <MdOutlineKeyboardArrowDown /> : <MdOutlineKeyboardArrowRight />;
                                        }
                                        return null;
                                    }}
                                />
                            </Col>

                            <Col xxl={16} xl={16} lg={16} md={16} sm={12} xs={24}>
                                {/* title */}
                                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <Controller
                                        name="title"
                                        control={control}
                                        defaultValue={''}
                                        render={({ field }) => (
                                            <CustomInput
                                                title={"Rol Ismi"}
                                                required={!editData}
                                                error={errors[field?.name]?.message}
                                                children={
                                                    <Whisper trigger="focus" speaker={<Tooltip>Gerekli</Tooltip>}>
                                                        <Input maxLength={10} value={field?.value} onChange={field?.onChange} />
                                                    </Whisper>
                                                }
                                            />
                                        )}
                                        rules={{ required: !editData && "Gerekli" }}
                                    />
                                </Col>
                            </Col>
                        </Row>

                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" appearance="primary">
                        Kaydet
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

const mapStateToProps = state => ({
    common_requesting: state.commonReducer.common_requesting,
    roles_data: state.roleReducer.roles_data,
});

const mapDispatchToProps = dispatch => ({
    saveRoleAction: (body, callback) => dispatch(saveRoleAction(body, callback)),
    updateRoleAction: (id, body, callback) => dispatch(updateRoleAction(id, body, callback)),
    rolesDataAction: (data) => dispatch(rolesDataAction(data)),
    getPermissionsAction: (callback) => dispatch(getPermissionsAction(callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(RolesCUModal)