import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, CheckPicker, Col, Form, Grid, IconButton, Input, Modal, SelectPicker, Tooltip, Uploader, Whisper } from 'rsuite';
import { CustomInput, DynamicallyTag } from '../../components/CustomComponents';
import { connect, useDispatch } from 'react-redux';
import './sub-category.scss';
import { subCategoriesDataAction, deleteImageSubCategoryAction, saveSubCategoryAction, uploadImagesCategoryAction, updateSubCategoryAction } from './redux/actions';
import { getCategoriesAction } from '../category/redux/actions';
import { CATEGORY_TYPES } from '../../utils/constants';


const SubCategoryCUModal = (props) => {

    const { open, setOpen, editData, setEditData, type } = props;
    const {
        handleSubmit,
        control, reset, setValue, getValues,
        formState: { errors }
    } = useForm();

    const [categoryItems, setCategoryItems] = useState(props.categories_data?.data?.map(item => ({ label: item.title, value: item.id })) ?? []);
    const categoryUpdateData = () => {
        if (categoryItems.length === 0) {
            setCategoryItems(props.categories_data?.data?.map(item => ({ label: item.title, value: item.id })) ?? []);
        }
    };

    useEffect(() => {
        if (!Boolean(props.categories_data)) {
            props.getCategoriesAction(1, 50);
        }
    }, [])

    useEffect(() => {
        if (editData) {
            setValue("title", editData?.title ?? "");
            setValue("categories", editData?.categories?.map(el => el.id) ?? []);
            setValue("type", editData?.type ?? "");
        }
    }, [editData])

    const onSubmit = data => {
        const formData = new FormData();

        Object.entries(data).forEach(([key, value]) => {
            if (key === "categories") {
                value?.forEach(item => {
                    formData.append(`${key}[]`, parseInt(item));
                })
            } else if (value) {
                formData.append(key, value);
            }
        })

        if (editData) {
            props.updateSubCategoryAction(editData.id, formData, callback);
        } else {
            props.saveSubCategoryAction(formData, callback);
        }
    };

    const callback = useCallback((data) => {
        if (data) {
            let updatedTableList = [];
            if (editData) {
                updatedTableList = props.sub_categories_data.data?.map(item => {
                    if (item.id === editData.id) {
                        return data;
                    }
                    return item;
                })
            } else {
                updatedTableList = [...props.sub_categories_data.data, data];
            }
            props.subCategoriesDataAction({ ...props.sub_categories_data, data: updatedTableList, total: props.sub_categories_data.total + 1 });
            resetFields();
        }
    }, []);

    const resetFields = () => {
        if (setEditData) {
            setEditData(false)
        }
        reset({ type: "", title: "" });
        setOpen(prev => ({ ...prev, [type]: false }))
    }
    return (
        <Modal backdrop={"static"} size={"lg"} open={open} onClose={() => resetFields()}>
            <Modal.Header>
                <Modal.Title>Alt Kategori Ekle</Modal.Title>
            </Modal.Header>

            <Form fluid onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Grid fluid className='tour_form'>
                        {/* categories */}
                        <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
                            <Controller
                                name="categories"
                                control={control}
                                defaultValue={[]}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Kategoriler"}
                                        required
                                        error={errors[field?.name]?.message}
                                        children={
                                            <CheckPicker
                                                data={categoryItems}
                                                value={field?.value}
                                                onChange={field?.onChange}
                                                onSearch={categoryUpdateData}
                                                onOpen={categoryUpdateData}
                                                style={{ width: 300 }}
                                            />
                                        }
                                    />
                                )}
                                rules={{ required: "Gerekli" }}
                            />
                        </Col>
                        {/* title */}
                        <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
                            <Controller
                                name="title"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Başlık"}
                                        required
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Whisper trigger="focus" speaker={<Tooltip>Gerekli</Tooltip>}>
                                                <Input value={field?.value} onChange={field?.onChange} />
                                            </Whisper>
                                        }
                                    />
                                )}
                                rules={{ required: "Gerekli" }}
                            />
                        </Col>
                        {/* type */}
                        <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
                            <Controller
                                name="type"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Alt Kategori Tipi"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <SelectPicker
                                                data={CATEGORY_TYPES.map(item => ({ label: item.label, value: item.value }))}
                                                value={field?.value}
                                                onChange={field?.onChange}
                                            />
                                        }
                                    />
                                )}
                            />
                        </Col>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" appearance="primary">
                        Kaydet
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

const mapStateToProps = state => ({
    common_requesting: state.commonReducer.common_requesting,
    sub_categories_data: state.subCategoryReducer.sub_categories_data,
    categories_data: state.categoryReducer.categories_data
});

const mapDispatchToProps = dispatch => ({
    getCategoriesAction: (page, limit) => dispatch(getCategoriesAction(page, limit)),
    saveSubCategoryAction: (body, callback) => dispatch(saveSubCategoryAction(body, callback)),
    updateSubCategoryAction: (id, body, callback) => dispatch(updateSubCategoryAction(id, body, callback)),
    subCategoriesDataAction: (data) => dispatch(subCategoriesDataAction(data)),
    deleteImageSubCategoryAction: (id, body, callback) => dispatch(deleteImageSubCategoryAction(id, body, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(SubCategoryCUModal)