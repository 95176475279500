import { COMMON_VALIDATION_ERROR, COMMON_ERROR, COMMON_REQUEST, COMMON_SUCCESS, LOGIN, USER_DATA, LOGOUT, LOGOUT_SUCCESS, IS_DARK_MODE, LIST_DATA } from "./constants";

export const loginAction = (body) => ({
    type: LOGIN,
    body
});
export const logoutAction = () => ({
    type: LOGOUT,
});
export const logoutSuccessAction = () => ({
    type: LOGOUT_SUCCESS
});

export const userDataAction = (data) => ({
    type: USER_DATA,
    data
});

export const commonRequestAction = (data) => ({
    type: COMMON_REQUEST,
    data
});

export const commonErrorAction = (data) => ({
    type: COMMON_ERROR,
    data
});

export const commonSuccessAction = (data) => ({
    type: COMMON_SUCCESS,
    data
});

export const commonValidationErrorAction = (data) => ({
    type: COMMON_VALIDATION_ERROR,
    data
});

export const setDarkModeAction = (data) => ({
    type: IS_DARK_MODE,
    data,
});

export const setListData = (data) => ({
    type: LIST_DATA,
    data
});