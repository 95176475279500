import React, { useCallback } from 'react';
import { Navbar, Nav, Dropdown, Avatar, Popover, Whisper, Col, Row } from 'rsuite';
import HomeIcon from '@rsuite/icons/legacy/Home';
import CogIcon from '@rsuite/icons/legacy/Cog';
import './common.scss';
import { connect } from 'react-redux';
import { logoutAction } from '../pages/redux/actions';

const userRenderMenu = (user, logout) => ({ onClose, left, top, className }, ref) => {
  const handleSelect = eventKey => {
    onClose();
    if (eventKey === "logout") {
      logout();
    } else if (eventKey === "/login") {
      window.location.replace(eventKey);
    }
  };

  return (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <Dropdown.Menu onSelect={handleSelect}>
        <Dropdown.Item panel style={{ padding: 10, width: 160 }}>
          <strong>{user?.fullName}</strong>
        </Dropdown.Item>
        <Dropdown.Separator />
        <Dropdown.Item>Profil</Dropdown.Item>
        <Dropdown.Item eventKey={"logout"}>Çıkış</Dropdown.Item>
      </Dropdown.Menu>
    </Popover>
  );
};

const AppNavbar = (props) => {

  const logout = useCallback(() => {
    props.logoutAction();
  }, [])
  return (
    <Navbar className='nav-bar'>
      <Row style={{ width: '100%', height: '100%', alignItems: 'center', display: 'flex' }}>
        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Navbar.Brand href="#">{`${props.user?.agency?.title ?? ""} Panel`}</Navbar.Brand>
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 10 }}>
          <Whisper placement="bottomEnd" trigger="click" speaker={userRenderMenu(props.user, logout)}>
            <div style={{ width: '45px', height: '45px', cursor: 'pointer' }}>
              <img
                src={props.user?.thumbnail || "https://picsum.photos/200"}
                style={{ width: '100%', height: '100%', objectFit: "cover", borderRadius: '50%' }}
                alt=""
              />
            </div>
          </Whisper>
        </Col>
      </Row>
    </Navbar>
  );
}
const mapStateToProps = state => ({
  common_requesting: state.commonReducer.common_requesting,
  user: state.commonReducer.user,
});

const mapDispatchToProps = dispatch => ({
  logoutAction: () => dispatch(logoutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppNavbar);
