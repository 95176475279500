import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, Col, Form, Grid, SelectPicker, Input, Modal, Tooltip, Uploader, Whisper } from 'rsuite';
import { CustomInput, DynamicallyTag } from '../../components/CustomComponents';
import { connect, useDispatch } from 'react-redux';
import './category.scss';
import CloseIcon from '@rsuite/icons/Close';
import { categoriesDataAction, deleteImageCategoryAction, saveCategoryAction, updateCategoryAction, uploadImagesCategoryAction } from './redux/actions';
import Swal from 'sweetalert2';
import { CATEGORY_TYPES } from '../../utils/constants';


const CategoryCUModal = (props) => {

    const { open, setOpen, editData, setEditData, type } = props;
    const {
        handleSubmit,
        control, reset, setValue, getValues,
        formState: { errors }
    } = useForm();

    useEffect(() => {
        if (editData) {
            setValue("title", editData?.title || "");
            setValue("type", editData?.type || "");
        }
    }, [editData])


    const onSubmit = data => {
        const formData = new FormData();

        Object.entries(data).forEach(([key, value]) => {
            if (value) {
                formData.append(key, value);
            }
        })

        if (editData) {
            props.updateCategoryAction(editData.id, formData, callback);
        } else {
            props.saveCategoryAction(formData, callback);
        }
    };

    const callback = useCallback((data) => {
        if (data) {
            let updatedTableList = [];
            if (editData) {
                updatedTableList = props.categories_data.data?.map(item => {
                    if (item.id === editData.id) {
                        return data;
                    }
                    return item;
                })
            } else {
                updatedTableList = [...props.categories_data.data, data];
            }
            props.categoriesDataAction({ ...props.categories_data, data: updatedTableList, total: props.categories_data.total + 1 });
            resetFields();
        }
    }, []);

    const resetFields = () => {
        if (setEditData) {
            setEditData(false)
        }
        reset({ type: "", title: "" });
        setOpen(prev => ({ ...prev, [type]: false }))
    }
    return (
        <Modal backdrop={"static"} size={"lg"} open={open} onClose={() => resetFields()}>
            <Modal.Header>
                <Modal.Title>Kategori Ekle</Modal.Title>
            </Modal.Header>

            <Form fluid onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Grid fluid className='tour_form'>
                        {/* title */}
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                            <Controller
                                name="title"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Başlık"}
                                        required
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Whisper trigger="focus" speaker={<Tooltip>Gerekli</Tooltip>}>
                                                <Input value={field?.value} onChange={field?.onChange} />
                                            </Whisper>
                                        }
                                    />
                                )}
                                rules={{ required: "Gerekli" }}
                            />
                        </Col>
                        {/* type */}
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                            <Controller
                                name="type"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Kategori Tipi"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <SelectPicker
                                                data={CATEGORY_TYPES.map(item => ({ label: item.label, value: item.value }))}
                                                value={field?.value}
                                                onChange={field?.onChange}
                                            />
                                        }
                                    />
                                )}
                            />
                        </Col>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" appearance="primary">
                        Kaydet
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

const mapStateToProps = state => ({
    common_requesting: state.commonReducer.common_requesting,
    categories_data: state.categoryReducer.categories_data,
});

const mapDispatchToProps = dispatch => ({
    saveCategoryAction: (body, callback) => dispatch(saveCategoryAction(body, callback)),
    updateCategoryAction: (id, body, callback) => dispatch(updateCategoryAction(id, body, callback)),
    categoriesDataAction: (data) => dispatch(categoriesDataAction(data)),
    deleteImageCategoryAction: (id, body, callback) => dispatch(deleteImageCategoryAction(id, body, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryCUModal)