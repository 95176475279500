import React, { useState, useEffect, useCallback, forwardRef } from 'react';
import axios from 'axios';
import { Button, ButtonToolbar, Col, Container, Dropdown, Grid, IconButton, Pagination, Popover, Row, Table, Whisper, useToaster, } from 'rsuite';
import { connect } from 'react-redux';
import TourCUModal from './TourCUModal';
import { toursDataAction, deleteTourAction, deleteImageTourAction, uploadImagesTourAction, getToursByAgencyAction, updateTourAction } from "./redux/actions"
import Swal from 'sweetalert2';
import MoreIcon from '@rsuite/icons/legacy/More';
import { CustomToaster, ImageUploadModal, PermissionsWrapper, SwalConfirm, SwalDeleteConfirm, renderMenu } from '../../components/CustomComponents';
import { hasPermission } from '../../utils/helpers';
import { IoEyeOff } from "react-icons/io5";
import { IoEye } from "react-icons/io5";
import { FaAngleDoubleUp } from "react-icons/fa";
import { FaAngleDoubleDown } from "react-icons/fa";

const ActionCell = ({ rowData, dataKey, callback, ...props }) => {
  return (
    <Table.Cell {...props} className="link-group">
      <Whisper placement="bottomEnd" trigger="click" speaker={renderMenu(rowData, callback, true, true, "tour")}>
        <IconButton appearance="subtle" icon={<MoreIcon />} />
      </Whisper>
    </Table.Cell>
  );
};

const Tours = (props) => {

  const [open, setOpen] = useState({ thumbnail: false, images: false, edit: false, create: false });
  const [isHide, setIsHide] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [editData, setEditData] = useState(false);

  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };

  useEffect(() => {
    if (hasPermission(props.user?.role?.permissions, "tour view")) {
      props.getToursByAgencyAction({ page: page, limit: limit });
    }
  }, [page, limit])

  const editHandler = (data, type) => {
    if (data) {
      setEditData(data);
      setOpen(prev => ({ ...prev, [type]: true }));
    }
  }

  const deleteHandler = (id) => {
    if (id) {
      SwalDeleteConfirm({
        onConfirm: (e) => {
          props.deleteTourAction(id, deleteCallback);
        },
        onCancel: (e) => { debugger }
      })
    }
  }

  const deleteCallback = (id) => {
    if (id) {
      const filteredList = props.tours_data.data?.filter(item => item.id !== id);
      props.toursDataAction({ ...props.tours_data, data: filteredList });
    }
  };

  const actionHandler = useCallback((eventKey, rowData) => {
    switch (eventKey) {
      case 1:
        editHandler(rowData, "images")
        break;
      case 2:
        editHandler(rowData, "thumbnail")
        break;
      case 3:
        editHandler(rowData, "edit")
        break;
      case 4:
        deleteHandler(rowData.id)
        break;
      default:
        break;
    }

  }, [])


  const hideAndHighlightHandler = (rowData, type, yesText, noText) => {
    if (rowData && hasPermission(props.user?.role?.permissions, "tour update")) {
      SwalConfirm({
        text: Boolean(rowData[type]) ? noText : yesText,
        onConfirm: (e) => {
          const formData = new FormData();
          formData.append(type, Boolean(rowData[type]) ? 0 : 1);

          props.updateTourAction(rowData.id, formData, (data) => hideAndHighlightCallback(data, type))
        },
        onCancel: (e) => { debugger }
      })
    }
  }

  const hideAndHighlightCallback = (data, type) => {
    if (data) {
      let updatedTableList = [];
      updatedTableList = props.tours_data.data?.map(item => {
        if (item.id === data.id) {
          return { ...data, [type]: parseInt(data[type]) };
        }
        return item;
      })
      props.toursDataAction({ ...props.tours_data, data: [...updatedTableList] });
    }
  };


  return (
    <PermissionsWrapper permission="tour view">
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px' }}>
          <h2>Turlar</h2>
          {hasPermission(props.user?.role?.permissions, "tour create") &&
            <Button appearance="primary" onClick={() => setOpen(prev => ({ ...prev, create: true }))} >
              Tur Oluştur
            </Button>
          }
        </div>
        <div style={{ height: 500, marginTop: '30px' }}>
          <Table data={props.tours_data?.data || []} bordered rowHeight={60} fillHeight>

            <Table.Column width={80} align="center" fixed>
              <Table.HeaderCell>Logo</Table.HeaderCell>
              <Table.Cell>
                {rowData => (
                  <img src={rowData?.thumbnail ?? "https://cdn.pixabay.com/photo/2023/02/18/11/00/icon-7797704_1280.png"} style={{ width: '40px', height: '40px', objectFit: "cover", borderRadius: '10px' }} />
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={200} flexGrow={2}>
              <Table.HeaderCell>Başlık</Table.HeaderCell>
              <Table.Cell dataKey="title" />
            </Table.Column>

            <Table.Column width={200} flexGrow={3}>
              <Table.HeaderCell>Alt Başlık</Table.HeaderCell>
              <Table.Cell dataKey="sub_title" />
            </Table.Column>

            <Table.Column width={100} flexGrow={1}>
              <Table.HeaderCell>Ülkeler</Table.HeaderCell>
              <Table.Cell dataKey="countries_to_visit" />
            </Table.Column>

            <Table.Column width={70} fixed>
              <Table.HeaderCell>Öne Çıkanlardan</Table.HeaderCell>
              <Table.Cell>
                {rowData => (
                  <IconButton appearance="subtle" style={{ background: Boolean(rowData.isHighlighted) ? 'green' : "red" }} icon={Boolean(rowData.isHighlighted) ? <FaAngleDoubleUp color="white" /> : <FaAngleDoubleDown color="white" />} onClick={() => hideAndHighlightHandler(rowData, "isHighlighted", "Bu Tur Öne Çıkanlardan olacak", "Bu Tur Öne Çıkanlardan olmayacak")} />
                )}
              </Table.Cell>
            </Table.Column>


            <Table.Column width={70} fixed>
              <Table.HeaderCell>Hide</Table.HeaderCell>
              <Table.Cell>
                {rowData => (
                  <IconButton appearance="subtle" icon={Boolean(rowData.is_hide) ? <IoEyeOff color="red" /> : <IoEye />} onClick={() => hideAndHighlightHandler(rowData, "is_hide", "Bu Tur Gizlenecek", "Bu Tur her kes için açılacak")} />
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={70} fixed>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <ActionCell dataKey="id" callback={actionHandler} />
            </Table.Column>
          </Table>
        </div>
        <div style={{ padding: 20 }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={['total', '-', 'limit', '|', 'pager']}
            total={props.tours_data?.total}
            limitOptions={[10, 30, 50]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
        {open.create && hasPermission(props.user?.role?.permissions, "tour create") ? <TourCUModal setOpen={setOpen} open={open.create} type={"create"} /> : null}

        {open.edit ? <TourCUModal setOpen={setOpen} open={open.edit} editData={editData} type={"edit"} setEditData={setEditData} /> : null}

        {open.thumbnail ? (
          <ImageUploadModal open={open.thumbnail} setOpen={setOpen} type={"thumbnail"} rowData={editData}
            uploadFunc={props.uploadImagesTourAction} deleteFunc={props.deleteImageTourAction}
            updateRowDataFunc={props.toursDataAction} tableList={props.tours_data} loading={props.common_requesting}
            setEditData={setEditData}
          />
        ) : null}

        {open.images ? (
          <ImageUploadModal open={open.images} setOpen={setOpen} type={"images"}
            rowData={editData}
            uploadFunc={props.uploadImagesTourAction}
            deleteFunc={props.deleteImageTourAction}
            updateRowDataFunc={props.toursDataAction} tableList={props.tours_data}
            loading={props.common_requesting}
            setEditData={setEditData}
          />
        ) : null}

      </div>
    </PermissionsWrapper>
  );
};
const mapStateToProps = state => ({
  common_requesting: state.commonReducer.common_requesting,
  tours_data: state.tourReducer.tours_data,
  common_error: state.commonReducer.common_error,
  user: state.commonReducer.user,
});

const mapDispatchToProps = dispatch => ({
  getToursByAgencyAction: (queryParams) => dispatch(getToursByAgencyAction(queryParams)),
  deleteTourAction: (id, callback) => dispatch(deleteTourAction(id, callback)),
  uploadImagesTourAction: (id, body, callback) => dispatch(uploadImagesTourAction(id, body, callback)),
  deleteImageTourAction: (id, body, callback) => dispatch(deleteImageTourAction(id, body, callback)),
  toursDataAction: (data) => dispatch(toursDataAction(data)),
  updateTourAction: (id, body, callback) => dispatch(updateTourAction(id, body, callback))

});

export default connect(mapStateToProps, mapDispatchToProps)(Tours);
