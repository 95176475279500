import React, { useState, useEffect, useCallback, forwardRef } from 'react';
import axios from 'axios';
import { Button, ButtonToolbar, Col, Container, Dropdown, Grid, IconButton, Pagination, Popover, Row, Table, Whisper, useToaster, } from 'rsuite';
import { connect } from 'react-redux';
import HotelCUModal from './HotelCUModal';
import { hotelsDataAction, deleteHotelAction, deleteImageHotelAction, uploadImagesHotelAction, getHotelsByAgencyAction, updateHotelAction } from "./redux/actions"
import Swal from 'sweetalert2';
import MoreIcon from '@rsuite/icons/legacy/More';
import { CustomToaster, ImageUploadModal, PermissionsWrapper, SwalConfirm, SwalDeleteConfirm, renderMenu } from '../../components/CustomComponents';
import { hasPermission } from '../../utils/helpers';
import { IoEyeOff } from "react-icons/io5";
import { IoEye } from "react-icons/io5";

const ActionCell = ({ rowData, dataKey, callback, ...props }) => {
  return (
    <Table.Cell {...props} className="link-group">
      <Whisper placement="bottomEnd" trigger="click" speaker={renderMenu(rowData, callback, true, true, "hotel")}>
        <IconButton appearance="subtle" icon={<MoreIcon />} />
      </Whisper>
    </Table.Cell>
  );
};

const Hotels = (props) => {

  const [open, setOpen] = useState({ thumbnail: false, images: false, edit: false, create: false });
  const [isHide, setIsHide] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [editData, setEditData] = useState(false);

  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };

  useEffect(() => {
    if (hasPermission(props.user?.role?.permissions, "hotel view")) {
      props.getHotelsByAgencyAction({ page: page, limit: limit });
    }
  }, [page, limit])

  const editHandler = (data, type) => {
    if (data) {
      setEditData(data);
      setOpen(prev => ({ ...prev, [type]: true }));
    }
  }

  const deleteHandler = (id) => {
    if (id) {
      SwalDeleteConfirm({
        onConfirm: (e) => {
          props.deleteHotelAction(id, deleteCallback);
        },
        onCancel: (e) => { debugger }
      })
    }
  }

  const deleteCallback = useCallback((id) => {
    if (id) {
      let filteredList = props.hotels_data.data?.filter(item => item.id !== id);
      props.hotelsDataAction({ ...props.hotels_data, data: filteredList });
    }
  }, []);

  const actionHandler = useCallback((eventKey, rowData) => {
    switch (eventKey) {
      case 1:
        editHandler(rowData, "images")
        break;
      case 2:
        editHandler(rowData, "thumbnail")
        break;
      case 3:
        editHandler(rowData, "edit")
        break;
      case 4:
        deleteHandler(rowData.id)
        break;
      default:
        break;
    }

  }, [])


  const hideHandler = (rowData) => {
    if (rowData && hasPermission(props.user?.role?.permissions, "hotel update")) {
      SwalConfirm({
        text: Boolean(rowData.is_hide) ? "Bu Otel her kes için açılacak" : "Bu Otel Gizlenecek",
        onConfirm: (e) => {
          const formData = new FormData();
          formData.append("is_hide", Boolean(rowData.is_hide) ? 0 : 1);

          props.updateHotelAction(rowData.id, formData, hideCallback)
        },
        onCancel: (e) => { debugger }
      })
    }
  }

  const hideCallback = (data) => {
    if (data) {
      let updatedTableList = [];
      updatedTableList = props.hotels_data.data?.map(item => {
        if (item.id === data.id) {
          return { ...data, is_hide: parseInt(data.is_hide) };
        }
        return item;
      })
      props.hotelsDataAction({ ...props.hotels_data, data: [...updatedTableList] });
    }
  };


  return (
    <PermissionsWrapper permission="hotel view">
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px' }}>
          <h2>Oteller</h2>
          {hasPermission(props.user?.role?.permissions, "hotel create") &&
            <Button appearance="primary" onClick={() => setOpen(prev => ({ ...prev, create: true }))} >
              Otel Oluştur
            </Button>
          }
        </div>
        <div style={{ height: 500, marginTop: '30px' }}>
          <Table data={props.hotels_data?.data || []} bordered rowHeight={60} fillHeight>

            <Table.Column width={80} align="center" fixed>
              <Table.HeaderCell>Logo</Table.HeaderCell>
              <Table.Cell>
                {rowData => (
                  <img src={rowData?.thumbnail ?? ""} style={{ width: '40px', height: '40px', objectFit: "cover", borderRadius: '10px' }} />
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={200} flexGrow={2}>
              <Table.HeaderCell>Isim</Table.HeaderCell>
              <Table.Cell dataKey="name" />
            </Table.Column>

            <Table.Column width={200} flexGrow={3}>
              <Table.HeaderCell>Yerleşim</Table.HeaderCell>
              <Table.Cell>
                {rowData => (
                  <p>{`${rowData?.country ?? ""} ${rowData?.city ?? ""}`}</p>
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={100} flexGrow={1}>
              <Table.HeaderCell>Adres</Table.HeaderCell>
              <Table.Cell dataKey="address" />
            </Table.Column>

            <Table.Column width={70} fixed>
              <Table.HeaderCell>Gizle</Table.HeaderCell>
              <Table.Cell>
                {rowData => (
                  <IconButton appearance="subtle" icon={Boolean(rowData.is_hide) ? <IoEyeOff color="red" /> : <IoEye />} onClick={() => hideHandler(rowData)} />
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={70} fixed>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <ActionCell dataKey="id" callback={actionHandler} />
            </Table.Column>
          </Table>
        </div>
        <div style={{ padding: 20 }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={['total', '-', 'limit', '|', 'pager']}
            total={props.hotels_data?.total}
            limitOptions={[10, 30, 50]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
        {open.create && hasPermission(props.user?.role?.permissions, "hotel create") ? <HotelCUModal setOpen={setOpen} open={open.create} type={"create"} /> : null}

        {open.edit ? <HotelCUModal setOpen={setOpen} open={open.edit} editData={editData} type={"edit"} setEditData={setEditData} /> : null}

        {open.thumbnail ? (
          <ImageUploadModal open={open.thumbnail} setOpen={setOpen} type={"thumbnail"} rowData={editData}
            uploadFunc={props.uploadImagesHotelAction} deleteFunc={props.deleteImageHotelAction}
            updateRowDataFunc={props.hotelsDataAction} tableList={props.hotels_data} loading={props.common_requesting}
            setEditData={setEditData}
          />
        ) : null}

        {open.images ? (
          <ImageUploadModal open={open.images} setOpen={setOpen} type={"images"}
            rowData={editData}
            uploadFunc={props.uploadImagesHotelAction}
            deleteFunc={props.deleteImageHotelAction}
            updateRowDataFunc={props.hotelsDataAction} tableList={props.hotels_data}
            loading={props.common_requesting}
            setEditData={setEditData}
          />
        ) : null}

      </div>
    </PermissionsWrapper>
  );
};
const mapStateToProps = state => ({
  common_requesting: state.commonReducer.common_requesting,
  hotels_data: state.hotelReducer.hotels_data,
  common_error: state.commonReducer.common_error,
  user: state.commonReducer.user,
});

const mapDispatchToProps = dispatch => ({
  getHotelsByAgencyAction: (queryParams) => dispatch(getHotelsByAgencyAction(queryParams)),
  deleteHotelAction: (id, callback) => dispatch(deleteHotelAction(id, callback)),
  uploadImagesHotelAction: (id, body, callback) => dispatch(uploadImagesHotelAction(id, body, callback)),
  deleteImageHotelAction: (id, body, callback) => dispatch(deleteImageHotelAction(id, body, callback)),
  hotelsDataAction: (data) => dispatch(hotelsDataAction(data)),
  updateHotelAction: (id, body, callback) => dispatch(updateHotelAction(id, body, callback))

});

export default connect(mapStateToProps, mapDispatchToProps)(Hotels);
