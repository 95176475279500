
import { call, put, all, takeLatest } from "redux-saga/effects";
import { commonErrorAction, commonRequestAction, commonSuccessAction, commonValidationErrorAction } from "../../redux/actions";
import { CREATE_ROLE, DELETE_ROLE, GET_PERMISSIONS, GET_ROLES, UPDATE_ROLE, } from "./constants";
import { createRole, deleteRole, getPermissions, getRoles, updateRole } from "../../../services/services";
import { rolesDataAction } from "./actions";

function* createRoleSaga({ body, callback }) {
    try {
        const response = yield call(createRole, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* updateRoleSaga({ id, body, callback }) {
    try {
        const response = yield call(updateRole, id, body);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(response.data?.data);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* deleteRoleSaga({ id, callback }) {
    try {
        const response = yield call(deleteRole, id);
        if (response.status === 200) {
            yield put(commonSuccessAction({ status: "Success", message: response.data?.message }));
            if (callback) {
                callback(id);
            }
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getRolesSaga({ page, limit }) {
    try {
        const response = yield call(getRoles, page, limit);
        if (response.status === 200) {
            yield put(rolesDataAction(response.data?.data ?? ""));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(commonValidationErrorAction({ status: error.response?.status, errors: error.response?.data?.errors }));
        } else {
            yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
        }
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getPermissionsSaga({ callback }) {
    try {
        const response = yield call(getPermissions);
        if (response.status === 200) {
            if (callback) {
                callback(response.data?.data ?? []);
            }
        }
    } catch (error) {
        yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
    } finally {
        yield put(commonRequestAction(false));
    }
}


export default all([
    takeLatest(CREATE_ROLE, createRoleSaga),
    takeLatest(UPDATE_ROLE, updateRoleSaga),
    takeLatest(DELETE_ROLE, deleteRoleSaga),
    takeLatest(GET_ROLES, getRolesSaga),
    takeLatest(GET_PERMISSIONS, getPermissionsSaga),
]);