import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, Col, Form, Grid, IconButton, Input, Modal, SelectPicker, Tooltip, Uploader, Whisper } from 'rsuite';
import { CustomInput, DynamicallyTag } from '../../components/CustomComponents';
import { connect, useDispatch } from 'react-redux';
import './user.scss';
import CloseIcon from '@rsuite/icons/Close';
import { usersDataAction, deleteImageUserAction, saveUserAction, updateUserAction, uploadImagesUserAction } from './redux/actions';
import Swal from 'sweetalert2';
import { isValidIBAN } from 'ibantools';
import { ROLES } from '../../utils/constants';
import { validateUsername } from '../../utils/helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getRolesAction } from '../role/redux/actions';

// Define your schema
const validationSchema = yup.object().shape({
    password: yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(/[a-z]/, 'Password must include at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must include at least one uppercase letter')
        .matches(/\d/, 'Password must include at least one number')
        .matches(/[@$!%*?&]/, 'Password must include at least one special character')
});

const UserCUModal = (props) => {

    const { open, setOpen, editData, setEditData, type } = props;
    const {
        handleSubmit,
        control, reset, setValue, getValues,
        formState: { errors }
    } = useForm({
        resolver: !editData && yupResolver(validationSchema),
        defaultValues: {
            password: ''
        }
    });

    const [roleItems, setRoleItems] = useState(props.roles_data?.data?.map(item => ({ label: item.title, value: item.id })) ?? []);
    const roleUpdateData = () => {
        if (roleItems.length === 0) {
            setRoleItems(props.roles_data?.data?.map(item => ({ label: item.title, value: item.id })) ?? []);
        }
    };

    useEffect(() => {
        if (editData) {
            setValue("fullName", editData?.fullName || "");
            setValue("email", editData?.email || "");
            setValue("username", editData?.username || "");
            setValue("role_id", editData?.role_id || "");
        }
    }, [editData])

    useEffect(() => {
        if (!Boolean(props.roles_data)) {
            props.getRolesAction(1, 50);
        }
    }, [])


    const onSubmit = data => {
        const formData = new FormData();

        Object.entries(data).forEach(([key, value]) => {
            if (value) {
                formData.append(key, value);
            }
        })

        if (editData) {
            props.updateUserAction(editData.id, formData, callback);
        } else {
            props.saveUserAction(formData, callback);
        }
    };

    const callback = useCallback((data) => {
        if (data) {
            let updatedTableList = [];
            if (editData) {
                updatedTableList = props.users_data.data?.map(item => {
                    if (item.id === editData.id) {
                        return data;
                    }
                    return item;
                })
            } else {
                updatedTableList = [...props.users_data.data, data];
            }
            props.usersDataAction({ ...props.users_data, data: updatedTableList, total: props.users_data.total + 1 });
            resetFields();
        }
    }, []);

    const resetFields = () => {
        if (setEditData) {
            setEditData(false)
        }
        reset();
        setOpen(prev => ({ ...prev, [type]: false }))
    }

    return (
        <Modal backdrop={"static"} size={"md"} open={open} onClose={() => resetFields()}>
            <Modal.Header>
                <Modal.Title>Kullanıcı Ekle</Modal.Title>
            </Modal.Header>

            <Form fluid onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Grid fluid className='tour_form'>
                        {/* fullName */}
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                            <Controller
                                name="fullName"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"İsim Soyisim"}
                                        required={!editData}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Whisper trigger="focus" speaker={<Tooltip>Gerekli</Tooltip>}>
                                                <Input value={field?.value} onChange={field?.onChange} />
                                            </Whisper>
                                        }
                                    />
                                )}
                                rules={{ required: !editData && "Gerekli" }}
                            />
                        </Col>
                        {/* email */}
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"E-posta"}
                                        required={!editData}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Input value={field?.value} onChange={field?.onChange} />
                                        }
                                    />
                                )}
                                rules={{
                                    required: !editData && "Gerekli",
                                    pattern: {
                                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                        message: "Geçerli bir e-posta adresi girin"
                                    }
                                }}
                            />
                        </Col>
                        {/* username */}
                        <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                            <Controller
                                name="username"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Kullanıcı adı"}
                                        required={!editData}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Input value={field?.value} onChange={field?.onChange} />
                                        }
                                    />
                                )}
                                rules={{ validate: !editData && validateUsername }}
                            />
                        </Col>
                        {/* password */}
                        <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                            <Controller
                                name="password"
                                control={control}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Şifre"}
                                        required={!editData}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Whisper trigger="focus" speaker={<Tooltip>Gerekli</Tooltip>}>
                                                <Input type='password' {...field} />
                                            </Whisper>
                                        }
                                    />
                                )}
                                rules={{ required: !editData && "Gerekli" }}
                            />
                        </Col>
                        {/* role_id */}
                        <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                            <Controller
                                name="role_id"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Rol"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <SelectPicker
                                                data={roleItems}
                                                value={field?.value}
                                                onChange={field?.onChange}
                                                style={{ width: 300 }}
                                                onSearch={roleUpdateData}
                                                onOpen={roleUpdateData}
                                            />
                                        }
                                    />
                                )}
                            />
                        </Col>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" appearance="primary">
                        Kaydet
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

const mapStateToProps = state => ({
    common_requesting: state.commonReducer.common_requesting,
    users_data: state.userReducer.users_data,
    roles_data: state.roleReducer.roles_data
});

const mapDispatchToProps = dispatch => ({
    getRolesAction: (page, limit) => dispatch(getRolesAction(page, limit)),
    saveUserAction: (body, callback) => dispatch(saveUserAction(body, callback)),
    updateUserAction: (id, body, callback) => dispatch(updateUserAction(id, body, callback)),
    usersDataAction: (data) => dispatch(usersDataAction(data)),
    deleteImageUserAction: (id, body, callback) => dispatch(deleteImageUserAction(id, body, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserCUModal)