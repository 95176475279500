import React from 'react'
import { Button, ButtonGroup, ButtonToolbar, Col, IconButton, Input, Row, SelectPicker } from 'rsuite';
import { CustomInput, DynamicallyTag } from '../../../components/CustomComponents';
import CloseIcon from '@rsuite/icons/Close';
import { FaAngleDoubleUp } from "react-icons/fa";
import { FaAngleDoubleDown } from "react-icons/fa";
import { CURRENCIES } from '../../../utils/constants';

const HotelRooms = (props) => {
    const { setRooms, rooms } = props;

    const newRoom = () => {
        setRooms(prev => [({
            title: "", room_type: "",
            isHighlighted: 0, amenities: [],
            capacity: "", price: "", currency: "USD",
            cancel_policies: [{ title: "", description: "" }, ...prev?.cancel_policies ?? []]
        }), ...prev])
    }

    const newCancelPolicy = (roomIndex) => {
        setRooms(prev => {
            prev = prev.map((el, i) => {
                if (roomIndex === i) {
                    return ({ ...el, cancel_policies: [{ title: "", description: "" }, ...el?.cancel_policies ?? []] })
                }
                return ({ ...el });
            })
            return prev;
        })
    }
    const removeCancelPolicy = (index, policyIndex) => {
        setRooms(prev => {
            prev = prev.map((room, roomIndex) => {
                if (roomIndex === index) {
                    room.cancel_policies = room.cancel_policies.filter((_, cancelPolicyIndex) => cancelPolicyIndex !== policyIndex)
                    return ({ ...room });
                }
                return ({ ...room });
            })
            return prev;
        });
    }

    const editRoom = (newValue, index, type, priceIndex) => {
        setRooms(prev => {
            prev = prev.map((v, i) => {
                if (index === i) {
                    if ((type === "title" || type === "room_type" || type === "isHighlighted"
                        || type === "amenities" || type === "capacity" || type === "price" || type === "currency") && priceIndex === undefined) {
                        return ({ ...v, [type]: newValue })
                    } else {
                        return ({
                            ...v,
                            cancel_policies: v.cancel_policies.map((prices, pi) => {
                                if (priceIndex === pi) {
                                    return ({ ...prices, [type]: newValue })
                                }
                                return ({ ...prices });
                            })
                        })
                    }
                }
                return ({ ...v })
            });
            return prev;
        })
    }

    const removeRoom = (index) => {
        setRooms(prev => prev.filter((_, i) => index !== i));
    }

    return (
        <>
            {/* rooms */}
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Button appearance="primary" onClick={() => newRoom()}>
                    Oda Ekle
                </Button>
            </Col>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                {rooms?.map((el, index) => (
                    <div key={index} className='program_item'>
                        <h4 className='program_number'>{`Oda ${rooms.length - index}`}</h4>
                        <Row>
                            {/* title */}
                            <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
                                <CustomInput
                                    title={"Başlık"}
                                    children={
                                        <Input value={el?.title} onChange={(value) => editRoom(value, index, "title")} />
                                    }
                                />
                            </Col>
                            {/* room_type */}
                            <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                                <CustomInput
                                    title={"Oda tipi"}
                                    children={
                                        <Input value={el?.room_type} onChange={(value) => editRoom(value, index, "room_type")} />
                                    }
                                />
                            </Col>
                            {/* isHighlighted */}
                            <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                                <CustomInput
                                    title={"Öne Çıkanlardan?"}
                                    children={
                                        <ButtonToolbar>
                                            <ButtonGroup>
                                                <Button style={{ background: el.isHighlighted === 1 ? '#2c792f' : "#c5c5c5" }} onClick={() => editRoom(1, index, "isHighlighted")} appearance={"primary"} endIcon={<FaAngleDoubleUp />}>Evet</Button>
                                                <Button style={{ background: el.isHighlighted === 0 ? '#eb3e3e' : "#c5c5c5" }} onClick={() => editRoom(0, index, "isHighlighted")} appearance={"primary"} endIcon={<FaAngleDoubleDown />}>Hayır</Button>
                                            </ButtonGroup>
                                        </ButtonToolbar>
                                    }
                                />
                            </Col>
                            {/* amenities */}
                            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                <CustomInput
                                    title={"Olanaklar"}
                                    children={
                                        <div className='dynamically_tag_wrapper'>
                                            <DynamicallyTag tags={el?.amenities ?? []} setTags={(value) => editRoom(value, index, "amenities")} />
                                        </div>
                                    }
                                />
                            </Col>
                            {/* capacity */}
                            <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                                <CustomInput
                                    title={"Insan Kapasitesi"}
                                    children={
                                        <Input type='number' value={el?.capacity} onChange={(value) => editRoom(value, index, "capacity")} />
                                    }
                                />
                            </Col>
                            {/* price */}
                            <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                                <CustomInput
                                    title={"Oda Fiyatı"}
                                    children={
                                        <Input type='number' value={el?.price} onChange={(value) => editRoom(value, index, "price")} />
                                    }
                                />
                            </Col>
                            <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                                <CustomInput
                                    title={"Para Birimi"}
                                    children={
                                        <SelectPicker
                                            data={CURRENCIES.map(item => ({ label: item.title, value: item.value }))}
                                            value={el?.currency}
                                            onChange={(value) => editRoom(value, index, "currency")}
                                            style={{ width: 300 }}
                                        />
                                    }
                                />
                            </Col>
                        </Row>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <h5 style={{ fontWeight: 'bold' }}>Iptal Politikası</h5>
                            <Button appearance="primary" onClick={() => newCancelPolicy(index)}>
                                Ekle
                            </Button>
                        </Col>
                        {el?.cancel_policies?.map((policy, policyIndex) => (
                            <Row key={policyIndex} className='date_price_item'>
                                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <CustomInput
                                        title={"Başlık"}
                                        children={
                                            <Input value={policy?.title} onChange={(value) => editRoom(value, index, "title", policyIndex)} />
                                        }
                                    />
                                </Col>
                                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <CustomInput
                                        title={"Tanıtım"}
                                        children={
                                            <Input as={"textarea"} rows={3} value={policy?.description} onChange={(value) => editRoom(value, index, "description", policyIndex)} />
                                        }
                                    />
                                </Col>
                                <IconButton color="red" appearance="primary" size="xs" className='close-btn' circle icon={<CloseIcon />} onClick={() => removeCancelPolicy(index, policyIndex)} />
                            </Row>
                        ))}

                        <IconButton color="red" appearance="primary" size="xs" className='close-btn' circle icon={<CloseIcon />} onClick={() => removeRoom(index)} />
                    </div>
                ))}
            </Col>
        </>
    )
}

export default HotelRooms