import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, Col, Form, Grid, SelectPicker, Input, Modal, Tooltip, Uploader, Whisper } from 'rsuite';
import { CustomInput, DynamicallyTag } from '../../components/CustomComponents';
import { connect, useDispatch } from 'react-redux';
import { bannersDataAction, deleteImageBannerAction, saveBannerAction, updateBannerAction, uploadImagesBannerAction } from './redux/actions';
import { BANNER_SHAPES } from '../../utils/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const schema = Yup.object().shape({
    link_to: Yup.string()
        .url('Invalid URL format')
        .optional(),
});


const BannerCUModal = (props) => {

    const { open, setOpen, editData, setEditData, type } = props;
    const {
        handleSubmit,
        control, reset, setValue, getValues,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            link_to: '',
        },
    });

    useEffect(() => {
        if (editData) {
            setValue("title", editData?.title || "");
            setValue("shape", editData?.shape || "");
            setValue("description", editData?.description || "");
            setValue("link_to", editData?.link_to || "");
        }
    }, [editData])


    const onSubmit = data => {
        const formData = new FormData();

        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value ?? "");
        })

        if (editData) {
            props.updateBannerAction(editData.id, formData, callback);
        } else {
            props.saveBannerAction(formData, callback);
        }
    };

    const callback = useCallback((data) => {
        if (data) {
            let updatedTableList = [];
            if (editData) {
                updatedTableList = props.banners_data.data?.map(item => {
                    if (item.id === editData.id) {
                        return data;
                    }
                    return item;
                })
            } else {
                updatedTableList = [...props.banners_data.data, data];
            }
            props.bannersDataAction({ ...props.banners_data, data: updatedTableList, total: props.banners_data.total + 1 });
            resetFields();
        }
    }, []);

    const resetFields = () => {
        if (setEditData) {
            setEditData(false)
        }
        reset({ type: "", title: "" });
        setOpen(prev => ({ ...prev, [type]: false }))
    }
    return (
        <Modal backdrop={"static"} size={"lg"} open={open} onClose={() => resetFields()}>
            <Modal.Header>
                <Modal.Title>Afiş Ekle</Modal.Title>
            </Modal.Header>

            <Form fluid onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Grid fluid className='tour_form'>
                        {/* title */}
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                            <Controller
                                name="title"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Başlık"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Input value={field?.value} onChange={field?.onChange} />
                                        }
                                    />
                                )}
                            />
                        </Col>
                        {/* shape */}
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                            <Controller
                                name="shape"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Şekil Tipi"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <SelectPicker
                                                data={BANNER_SHAPES.map(item => ({ label: item.label, value: item.value }))}
                                                value={field?.value}
                                                onChange={field?.onChange}
                                            />
                                        }
                                    />
                                )}
                            />
                        </Col>
                        {/* link_to */}
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Controller
                                name="link_to"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Sayfa Linki"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Input value={field?.value} onChange={field?.onChange} />
                                        }
                                    />
                                )}
                            />
                        </Col>
                        {/* description */}
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Controller
                                name="description"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <CustomInput
                                        title={"Tanıtım"}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Input as={"textarea"} rows={4} value={field?.value} onChange={field?.onChange} />
                                        }
                                    />
                                )}
                            />
                        </Col>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" appearance="primary">
                        Kaydet
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

const mapStateToProps = state => ({
    common_requesting: state.commonReducer.common_requesting,
    banners_data: state.bannerReducer.banners_data,
});

const mapDispatchToProps = dispatch => ({
    saveBannerAction: (body, callback) => dispatch(saveBannerAction(body, callback)),
    updateBannerAction: (id, body, callback) => dispatch(updateBannerAction(id, body, callback)),
    bannersDataAction: (data) => dispatch(bannersDataAction(data)),
    deleteImageBannerAction: (id, body, callback) => dispatch(deleteImageBannerAction(id, body, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(BannerCUModal)